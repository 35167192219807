import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { CoreService } from 'src/app/core/services/core.service';
import { MenuCategory } from 'src/app/core/services/menu-categories.service';
import { EventBrokerService, IEventListener } from '../../../../shared/services/eventBroker.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { DisplayStyle } from 'src/app/store/models/ISiteSettings';
import { setSiteSettingsDisplayStyleAction } from 'src/app/store/actions/SiteSettings.actions';

declare var $: any;

@Component({
  selector: 'fc-menu-tree',
  templateUrl: './menu-tree.component.html',
  styleUrls: ['./menu-tree.component.scss']
})
export class MenuTreeComponent implements OnInit, AfterViewChecked {
  @Input() isLeftNav?: boolean;
  @Input() isMobile?: boolean;
  @Output() menuLoaded = new EventEmitter<boolean>()
  private resizeMenuEventListener: IEventListener;
  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  menuCategoriesData?: MenuCategory[];
  bigBetsLargeMenuCategoriesData?: MenuCategory[];
  bigBetsSmallMenuCategoriesData?: MenuCategory[];

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private coreService: CoreService,
    private eventBroker: EventBrokerService,
  ) {
    this.resizeMenuEventListener = eventBroker
      .listen<void>('resizeMenu', () => this.resizeMenu())
      ;
  }

  ngOnInit() {
    this.getMenuCategories()
  }

  ngAfterViewChecked(): void {
    if (!this.isLeftNav) {
      this.listenEvent()
    }
    this.resizeMenu();
  }

  private resizeMenu() {
    if (!this.isMobile) {
      if (!$('.footer')[0]) {
        return;
      }
      const footerTop: number = $('.footer')[0].getBoundingClientRect().top;
      const winHeight: number = $(window).height();
      $('.side-menu-container').css({
        height: winHeight - 240 - ((footerTop < winHeight) ? (winHeight - footerTop) : 0),
        width: $('.leftCol').width() - 10,
      });
    } else {
      $('.leftCol').hide()
    }
  }

  private listenEvent(): void {
    $('.navbar-toggler.menu-toggle, .btn.menu-toggle').off('click').on('click', function (e: any) {
      e.preventDefault();

      $('.tree-menu').toggleClass('active');
    });
  }

  private getMenuCategories(): void {
    this.coreService.getMenuCategories()
      .subscribe(menuCategoriesData => {
        this.menuCategoriesData = menuCategoriesData;

        // const largeFormatData = menuCategoriesData.find(x => x.name === "Large Format")?.children?.find(x => x.name === "Big Bets")
        // const largeFormatDataArray = []

        // if (largeFormatData) {
        //   largeFormatDataArray.push(largeFormatData)

        //   this.bigBetsLargeMenuCategoriesData = largeFormatDataArray
        // }

        // const smallFormatData = menuCategoriesData.find(x => x.name === "Small Format")?.children?.find(x => x.name === "Big Bets")
        // const smallFormatDataArray = []

        // if (smallFormatData) {
        //   smallFormatDataArray.push(smallFormatData)

        //   this.bigBetsSmallMenuCategoriesData = smallFormatDataArray
        // }

        this.menuLoaded.emit(true)
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName)
  }

  navigateToCategoryId(categoryId: number) {
    this.sharedService.navigateToCategoryId(categoryId)
  }

  displayInventoryModal() {
    $('.tree-menu').toggleClass('active');
    $('#inventory-modal').modal('show');
  }

  openEPOP() {
    window.open("https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx", "_blank")
  }

  openSellSheets() {
    window.open("https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui", "_blank")
  }

  switchDisplayStyle() {
    this.store.dispatch(setSiteSettingsDisplayStyleAction({
        displayStyle: (this.displayStyle === DisplayStyle.Cards)
          ? DisplayStyle.Rows
          : DisplayStyle.Cards
    }));
    $('.tree-menu').toggleClass('active');

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>('productsLoaded', undefined);
    }, 500);
  }
}
