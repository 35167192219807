import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { DisplayStyle, ISiteSettings } from 'src/app/store/models/ISiteSettings';
import { AppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ProductProperties } from '../../interfaces/product-properties';

declare var $: any;

@Component({
  selector: 'fc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, AfterViewInit {
  @Input() product: any;
  @Input() isPrint?: boolean;
  @Input() categoryId?: number;

  @Output() downloadCardClicked = new EventEmitter<any>()
  @Output() thumbnailClicked = new EventEmitter<any>()

  productName?: string
  productHas360 = false
  imageName = ""
  titleGA = ""
  propertyDimensions?: string;
  imagesCount: number = 1;

  unfrozenProduct: any;

  public isCardLayout = true;

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService) {
    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
      })
      ;
  }

  ngOnInit() {
    // Despite doing JSON.parse(JSON.stringify) in the Apollo response, this object still gets frozen at some point
    this.unfrozenProduct = JSON.parse(JSON.stringify(this.product));

    this.highlightNotesIfNeeded()

    let productProperties: ProductProperties = {};
    try {
      productProperties = JSON.parse(this.unfrozenProduct.properties);

      this.productHas360 = productProperties.has360View === true ? true : false;
      this.propertyDimensions = productProperties.dimensions;
      this.imagesCount = productProperties.imagesCount ?? 1;
    } catch (e) {
      this.productHas360 = false
    }

    if (this.sharedService.environmentIsPBNA()) {
      this.titleGA = `${this.unfrozenProduct.eqi}- ${this.unfrozenProduct.name}`;
      if (this.imagesCount > 1) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.unfrozenProduct.eqi}-1x300.jpg`;
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.unfrozenProduct.eqi}x300.jpg`;
      }
    } else {
      if (this.unfrozenProduct.productColors.length > 0 && this.unfrozenProduct.productColors[0].fullEqi !== null) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.unfrozenProduct.productColors[0].fullEqi}x300.jpg`
        this.titleGA = `${this.unfrozenProduct.productColors[0].fullEqi}- ${this.unfrozenProduct.name}`
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.unfrozenProduct.eqi}x300.jpg`
        this.titleGA = `${this.unfrozenProduct.eqi}- ${this.unfrozenProduct.name}`
      }
    }
  }

  ngAfterViewInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
  }

  navigateToBugId(bugId: number) {
    $('.badge').tooltip('hide')

    this.sharedService.navigateToBugId(bugId)
  }

  download() {
    this.downloadCardClicked.emit({ product: this.unfrozenProduct, categoryId: this.categoryId })
  }

  private highlightNotesIfNeeded() {
    if (this.unfrozenProduct && this.unfrozenProduct.bugs) {
      if (this.unfrozenProduct.bugs.filter(function (e: any) { return e.id === '4'; }).length > 0) {
        if (this.unfrozenProduct.notes) {
          this.unfrozenProduct.notes = this.unfrozenProduct.notes.replace('<hi>', '<div class="badge badge-pill badge-warning">')
          this.unfrozenProduct.notes = this.unfrozenProduct.notes.replace('</hi>', '</div><div>')
          this.unfrozenProduct.notes += '</div>';
        }
      }
    }
  }

  showThumbnailModal() {
    this.thumbnailClicked.emit({ product: this.unfrozenProduct, categoryId: this.categoryId })
  }
}
