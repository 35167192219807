import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends Query<Product> {
  override document = gql`
    query product($productId: Int!) {
      product(productId: $productId) {
        id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
          fullEqi, color {
            id, code, name, image
          }
        }, bugs {
          id, name, imageUrl
        }, properties
      }
    }`;
}
