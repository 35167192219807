import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MainLoginService } from './main-login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fc-main-login',
  templateUrl: './main-login.component.html',
  styleUrls: ['./main-login.component.scss']
})
export class MainLoginComponent implements OnDestroy {
  message: string = "";
  password?: string;

  private _subscriptions = new Subscription();

  constructor(
    private _mainLoginService: MainLoginService,
    private _router: Router,
  ) { }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  validateForm(): void {
    let forms = document.getElementsByClassName('needs-validation');

    Array.prototype.filter.call(forms, (form) => {
      if (form.checkValidity() === false) {
        event?.preventDefault();
        event?.stopPropagation();
      } else {
        this.login()
      }

      form.classList.add('was-validated');
    });
  }

  private login(): void {
    this._subscriptions.add(
      this._mainLoginService.login(this.password ?? '').subscribe(success => {
        if (success) {
          this.message = ""
          this._router.navigate(['/']);
        } else {
          this.message = "Wrong credentials. Please try again!";
          this.password = '';
        }
      })
    );
  }

}
