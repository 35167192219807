import { Component, OnInit } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { SearchService } from 'src/app/core/services/search.service'

import { environment } from 'src/environments/environment'
import { ClientFunctions } from "src/app/shared/interfaces/client-functions";

import { SearchResult } from 'src/app/public/interfaces/search-result';

import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'fc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchResults?: SearchResult[];
  searchTerm$ = new Subject<string>();

  clientFunctions: ClientFunctions = {
    mcoe: {},
    fsv: {},
    pbna: {},
  };

  constructor(
    public sharedService: SharedService,
    private searchService: SearchService) {
    this.searchService.search(this.searchTerm$)
      .subscribe(searchResults => {
        this.searchResults = searchResults

        $(".search-button-image").css('display', 'block')
        $(".search-spinner").css('display', 'none')
        $(".search-results-list").css('display', 'block')
      }, error => {
        console.error('there was an error sending the search', error);
      });
  }

  ngOnInit() {
    this.clientFunctions = {
      mcoe: {
        searchButtonClicked: () => {
          this.searchButtonClicked()
        }
      },
      fsv: {
        searchButtonClicked: () => {
          this.searchButtonClicked()
        }
      },
      pbna: {
        searchButtonClicked: () => {
          this.searchButtonClicked()
        }
      },
    }

    $(".search-results-list").css('display', 'none')

    $(document).on("click", () => {
      if ($(".search-results-list").is(':visible')) {
        $("#search-input").val('')
        this.resetSearchListResults()
      }
    });

    $("body").unbind('keyup').on('keyup', '#search-input', (e: any) => {
      if (e.keyCode === 13) {
        this.searchButtonClicked()
      }
    });
  }

  searchResultClicked(searchResult: SearchResult) {
    switch (searchResult.type) {
      case "Category":
        this.sharedService.navigateToCategoryId(searchResult.id)
        $("#search-input").val('')
        break;
      case "Bug":
        this.sharedService.navigateToBugId(searchResult.id)
        $("#search-input").val('')
        break;
      case "Product":
        this.sharedService.navigateToProductId(searchResult.id)
        $("#search-input").val('')
        break;
      case "Color":
        this.sharedService.navigateToColorId(searchResult.id)
        $("#search-input").val('')
        break;
    }
  }

  searchButtonClicked() {
    if ($('#search-input').val().length > 2) {
      this.sharedService.navigateToSearchByKeyword($('#search-input').val())
      this.resetSearchListResults()
    }
  }

  private resetSearchListResults() {
    this.searchTerm$.next()

    setTimeout(() => {
      $(".search-button-image").css('display', 'block')
      $(".search-spinner").css('display', 'none')
      $(".search-results-list").css('display', 'none')
      $("#search-input").blur()

      this.searchResults = undefined;
    }, 500);
  }
}
