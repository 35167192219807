import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MainLoginService } from './main-login.service';

@Injectable({
  providedIn: 'root'
})
export class MainLoginGuard implements CanActivate {
  constructor(
    private _mainLoginService: MainLoginService,
    private _router: Router,
  ) {

  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (environment.environmentId !== 'pbna') {
      return true;
    }

    return this._mainLoginService.isLoggedIn() ? true : this._router.parseUrl('main-login');
  }

}
