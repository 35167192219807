import { Injectable } from '@angular/core';

import { Setting } from '../interfaces/setting';

import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends Query<Setting> {
  override document = gql`
    query settingByKey($key: String!) {
      settingByKey(key: $key) {
        id, key, value, displayName
      }
    }`;
}
