import { Injectable } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { HomepageSection } from '../interfaces/homepage-section'

@Injectable({
  providedIn: 'root'
})
export class HomepageSectionCUDService {
  constructor(private apollo: Apollo) { }

  addSection(section: HomepageSection) {
    const addSection = gql`
      mutation createSection($section: AddFrontpageSectionInput!) {
          createSection(section: $section) {
              id
          }
      }`;

    return this.apollo.mutate({
      mutation: addSection,
      variables: {
        section: section
      }
    })
  }

  removeSection(section: HomepageSection) {
    const deleteSection = gql`
      mutation deleteSection($section: UpdateFrontpageSectionInput!) {
          deleteSection(section: $section)
      }`;

    return this.apollo.mutate({
      mutation: deleteSection,
      variables: {
        section: section
      }
    })
  }

  editSection(section: HomepageSection) {
    const updateSection = gql`
      mutation updateSection($section: UpdateFrontpageSectionInput!) {
          updateSection(section: $section) {
              id
          }
      }`;

    return this.apollo.mutate({
      mutation: updateSection,
      variables: {
        section: section
      }
    })
  }
}
