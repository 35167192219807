import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { SharedService } from "src/app/shared/services/shared.service";
import { AuthService } from "src/app/public/auth/auth.service";
import { AuthServiceAdmin } from "src/app/admin/auth.service";

@Injectable()

export class AuthGuardRestrict implements CanActivate {

    constructor(
        private router: Router,
        private sharedService: SharedService,
        private authService: AuthService,
        private authServiceAdmin: AuthServiceAdmin
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // if (route.routeConfig.path === "auth") {
        //     if (this.sharedService.environmentIsFSV()) {
        //         if (!this.authService.isFsvSecureAccess()) {
        //             return true
        //         }
        //     }
        // } else

        if (route.routeConfig?.path === "login") {
            if (!this.authServiceAdmin.userIsLoggedIn()) {
                // if (this.sharedService.environmentIsFSV()) {
                //     if (!this.authService.isFsvSecureAccess()) {
                //         this.router.navigate(['/auth'])

                //         return true
                //     }
                // }

                return true
            } else {
                // if (this.sharedService.environmentIsMCOE()) {
                this.router.navigate(['/admin/dashboard'])

                return true
                // }
            }
        }

        this.router.navigate(['/'])
        return false
    }

}
