import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PopupData } from 'src/app/admin/dashboard/dashboard-popups/dashboard-popups.component';
import { AdminService } from 'src/app/admin/services/admin.service';
import { CustomPopupService } from '../../services/custom-popup.service';
import { SharedService } from '../../services/shared.service';

declare var $: any;

@Component({
  selector: 'fc-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent implements OnInit, OnDestroy {

  @Input() popupKey?: 'custom-popup-1' | 'custom-popup-2';

  popupData: PopupData | null = null
  timestamp: number = 0

  private _subscriptions = new Subscription()

  constructor(
    private _adminService: AdminService,
    private _router: Router,
    public sharedService: SharedService,
    private _customPopupService: CustomPopupService
  ) { }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(routerEvent => routerEvent instanceof NavigationEnd),
        map(routerEvent => routerEvent as NavigationEnd),
        switchMap((navigation) =>
          this._adminService.getSettings()
            .pipe(
              map(settings => settings.filter(setting => setting.key === this.popupKey)),
              filter(popupSettings => popupSettings.length === 1),
              map(popupSettings => popupSettings[0]),
              map(customPopupSettings => JSON.parse(customPopupSettings.value) as PopupData),
              filter(popupData => popupData.isActive),
              tap(popupData => {
                this.popupData = popupData
                this.timestamp = Date.now()
              }),
              map(popupData => popupData.targetUrls.split(",")),
              filter((splittedTargetUrls) => {
                if (navigation.url === this.popupData?.actionUrl) { return false }
                if (navigation.url.startsWith('/print')) { return false }
                if (navigation.url.startsWith('/sellsheet')) { return false }
                if (splittedTargetUrls.includes("/")) {
                  if (navigation.url === "/") {
                    return true
                  }
                } else {
                  if (this.popupKey === "custom-popup-1" && navigation.url !== "/admin/dashboard") {
                    return true
                  } else {
                    const result = splittedTargetUrls.filter(targetUrl => navigation.url.startsWith(targetUrl));
                    return result && result.length > 0
                  }
                }

                return false
              }),
              tap(() => {
                if (this.popupKey === "custom-popup-1" && this.popupData?.actionUrl === "/products/categories/bigbets") {
                  this.sharedService.enableAccessBitBetsUrl()
                }

                if (this.popupData?.displayOneTimePerUser) {
                  if (this.popupKey && !this._customPopupService.customPopupAlreadyInLocalStorage(this.popupKey)) {
                    this._customPopupService.setCustomPopupInLocalStorage(this.popupKey)
                    this.showModal()
                  }
                } else {
                  if (this.popupData?.displayOneTimePerSession) {
                    if (this.popupKey && !this._customPopupService.customPopupAlreadyDisplayed(this.popupKey)) {
                      this._customPopupService.setCustomPopupAlreadyDisplayed(this.popupKey)
                      this.showModal()
                    }
                  } else {
                    this.showModal()
                  }
                }
              })
            )
        )
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe()
  }

  showModal(): void {
    if ($('#' + this.popupKey)) {
      setTimeout(() => {
        $("#black-overlay").addClass('visible')
        $('#' + this.popupKey).modal('show');
      }, 500);
    }
  }

  closeModal(): void {
    if ($('#' + this.popupKey)) {
      this.popupData = null

      $("#black-overlay").removeClass('visible')
      $('#' + this.popupKey).modal('hide');
    }
  }

  imgClicked(actionUrl: string): void {
    this.closeModal()

    if (actionUrl.startsWith('/')) {
      this._router.navigateByUrl(actionUrl)
    } else {
      window.open(actionUrl, '_blank')
    }
  }

}
