import { Component, OnInit, Input } from '@angular/core';

import { Product } from '../../interfaces/product';

import { SharedService } from 'src/app/shared/services/shared.service';
import { Sellsheet } from '../../interfaces/sellsheet';
import { ProductProperties } from '../../interfaces/product-properties';

@Component({
  selector: 'fc-sellsheet-template',
  templateUrl: './sellsheet-template.component.html',
  styleUrls: ['./sellsheet-template.component.scss']
})
export class SellsheetTemplateComponent implements OnInit {
  @Input() product?: Product

  sellSheetEconomics?: Object
  sellSheetDisplaySpec?: Object
  imageName = ""

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.product && this.product.sellSheet) {
      this.sellSheetEconomics = JSON.parse(this.product.sellSheet.economics)
      this.sellSheetDisplaySpec = JSON.parse(this.product.sellSheet.displaySpec)
    }

    if (this.sharedService.environmentIsPBNA()) {
      let imageCount: number = 1;
      try {
        const productProperties: ProductProperties = JSON.parse(this.product?.properties ?? '{}');
        imageCount = productProperties.imagesCount ?? 1;
      } catch (e) {
        console.log('Error parsing product properties: ' + e)
      }
      if (imageCount > 1) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.eqi}-1x600.jpg`;
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.eqi}x600.jpg`;
      }
    } else {
      if (this.product?.productColors && this.product?.productColors.length > 0 && this.product?.productColors[0].fullEqi !== null) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.productColors[0].fullEqi}x600.jpg`
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.eqi}x600.jpg`
      }
    }
  }
}
