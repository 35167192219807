import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { AdminService } from '../../services/admin.service'
import { Setting } from '../../interfaces/setting';
import { UploadType } from '../upload-type'

import { Observable, Subscription } from 'rxjs'

@Component({
  selector: 'fc-dashboard-homepage',
  templateUrl: './dashboard-homepage.component.html',
  styleUrls: ['./dashboard-homepage.component.scss']
})
export class DashboardHomepageComponent implements OnInit, OnDestroy {
  @Input() closeModalEvent?: Observable<void>;
  @Output() showUploadModalEvent = new EventEmitter<any>()
  @Output() showToastEvent = new EventEmitter<any>()

  homepageSettings?: Setting[]
  homepageAssets?: Setting[]

  private closeModalEventSubscription?: Subscription;

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.getSettings()

    this.closeModalEventSubscription = this.closeModalEvent?.subscribe(() =>
      this.getSettings()
    );
  }

  ngOnDestroy() {
    this.closeModalEventSubscription?.unsubscribe()
  }

  getSettings() {
    this.homepageSettings = []

    this.adminService.getSettings()
      .subscribe(settingsData => {
        this.homepageSettings = settingsData
        this.homepageAssets = settingsData.filter(function (e) {
          return e.key.startsWith('asset-homepage')
        })
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  viewAndEdit(homepageSetting: any) {
    const uploadModalContent = {
      uploadType: UploadType.HomepageAsset,
      itemId: homepageSetting.id,
      itemDisplayName: homepageSetting.displayName,
      itemValue: homepageSetting.value,
      itemAcceptFormats: 'image/jpeg,image/png',
      itemFormatsDescription: '.jpg, .png'
    }

    this.showUploadModalEvent.emit(uploadModalContent)
  }

  showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  needToRefreshData() {
    this.getSettings()
  }
}
