import { Component, OnInit, OnDestroy } from '@angular/core';

import { AdminService } from '../../services/admin.service'

@Component({
  selector: 'fc-dashboard-sellsheet',
  templateUrl: './dashboard-sellsheet.component.html',
  styleUrls: ['./dashboard-sellsheet.component.scss']
})
export class DashboardSellsheetComponent implements OnInit, OnDestroy {
  status: any[] = []
  refreshInterval: any

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.getSettingByKey()

    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(() => {
        this.getSettingByKey()
      }, 30000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval)
  }

  getSettingByKey() {
    this.status = []

    this.adminService.getSettingByKey("pdf-sellSheet-status")
      .subscribe(settingByKeyData => {
        var valueParsed = JSON.parse(settingByKeyData.value)

        if (valueParsed['status'] !== undefined && valueParsed['status'] !== null) {
          valueParsed['status'].forEach((status: any) => {
            var current: any = {}
            current['name'] = status.name
            current['status'] = status.status

            var generationTime = "--"

            if (status.status.startsWith('http') === true) {
              var statusDate = new Date(status.generationTime)

              generationTime = ("0" + (statusDate.getMonth() + 1)).slice(-2) + '-' + ("0" + (statusDate.getDate())).slice(-2) + '-' + statusDate.getFullYear() + ' ' + ("0" + (statusDate.getHours())).slice(-2) + ':' + ("0" + (statusDate.getMinutes())).slice(-2) + ':' + ("0" + (statusDate.getSeconds())).slice(-2)
            }

            current['generationTime'] = generationTime

            this.status.push(current)
          });
        }
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }
}
