<ng-container *ngIf="!sharedService.environmentIsPBNA()">
  <fc-sellsheet-template *ngFor="let product of products"
                         [product]="product">
  </fc-sellsheet-template>
</ng-container>

<ng-container *ngIf="sharedService.environmentIsPBNA()">
  <fc-sellsheet-template-toc-pbna [categories]="categories"></fc-sellsheet-template-toc-pbna>
  <fc-sellsheet-template-pbna *ngFor="let product of products; index as i"
                              [id]="product.id"
                              [product]="product"
                              [page]="i + offsetPage">
  </fc-sellsheet-template-pbna>
</ng-container>