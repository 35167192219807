import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { UploadType } from '../upload-type'
import { environment } from 'src/environments/environment'
import { AdminService } from '../../services/admin.service';
import { SharedService } from "src/app/shared/services/shared.service";

declare var $: any;

@Component({
  selector: 'fc-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
  @Input() modalContent: any

  @Output() showToastEvent = new EventEmitter<any>()
  @Output() closeModalEvent = new EventEmitter<any>()

  itemFileData: File | null = null;
  itemFileName: string | null = null;
  timestamp?: number

  constructor(
    private httpClient: HttpClient,
    private adminService: AdminService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.timestamp = Date.now()
  }

  closeModal() {
    $('#upload-modal').modal('hide');
    $("#black-overlay").removeClass('visible')

    this.restoreItemToDefault()
  }

  showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  restoreItemToDefault() {
    this.itemFileData = null;
    this.itemFileName = null;

    $("#getItemFile").val('');
    $('#btn-upload').text('Upload')
    $('#btn-upload').prop('disabled', true)
  }

  itemFileProgress(fileInput: any) {
    if (this.modalContent.uploadType == UploadType.AssetsZip) {
      let tempAssetsFileData = <File>fileInput.target.files[0];

      if (tempAssetsFileData.size !== null && tempAssetsFileData.size !== undefined) {
        if (tempAssetsFileData.size >= 30000000) {
          this.showToast("Assets zip file size is too big!")
        } else {
          this.itemFileData = <File>fileInput.target.files[0];
          this.itemFileName = this.itemFileData.name
        }
      }
    } else {
      this.itemFileData = <File>fileInput.target.files[0];
      this.itemFileName = this.itemFileData.name
    }
  }

  uploadItem() {
    $('#btn-upload').prop('disabled', true)
    $('#btn-upload').text('Please wait...')

    const formData = new FormData();
    formData.append('file', this.itemFileData ?? '');

    $('#btn-upload').text('0%');

    var apiEndoit = ""

    switch (this.modalContent.uploadType) {
      case UploadType.Excel:
        apiEndoit = "/upload/excel"
        break;
      case UploadType.AssetsZip:
        apiEndoit = "/upload/image/product?type=batch"
        break;
      case UploadType.HomepageAsset:
        apiEndoit = "/upload/asset?folder=homepage"
        break;
      case UploadType.ProductImages:
        apiEndoit = "/upload/product/360"
        break;
      case UploadType.ProductVideo:
        apiEndoit = "/upload/product/video"
        break;
    }

    this.httpClient.post(environment.endPoint + apiEndoit, formData, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(events => {
        if (events.type === HttpEventType.UploadProgress) {
          $('#btn-upload').text(Math.round(events.loaded / (events.total ?? 0) * 100) + '%');
        } else if (events.type === HttpEventType.Response) {
          if (events.status === 200) {
            if (this.modalContent.uploadType === UploadType.HomepageAsset) {
              this.updateAssetKeyValue(this.itemFileName ?? '')
            } else {
              this.showToast("File uploaded!<br><br>Updates will be available in few seconds.")
              this.closeModalEvent.emit()
            }
          } else {
            console.log('Error during file upload :', events.status, events.body)

            this.showToast("An error during the file upload has occured..." + ' - ' + events.status + ' - ' + events.body)
          }

          this.closeModal()
        }
      }, (error: HttpErrorResponse) => {
        console.log('Error during file upload :', error)

        this.closeModal()
        this.showToast("An error during the file upload has occured..." + ' - ' + error.status + ' - ' + error.statusText + ' - ' + error.message)
      });
  }

  updateAssetKeyValue(imageName: string) {
    this.adminService.editSetting(
      parseInt(String(this.modalContent.itemId)),
      "/assets/homepage/" + imageName,
      this.modalContent.itemDisplayName
    ).subscribe(editActionData => {
      this.showToast("File uploaded!<br><br>Updates will be available in few seconds.")
      this.closeModalEvent.emit()
    }, error => {
      console.error('there was an error sending the mutation', error);
      this.showToast("An error during the mutation has occured...")
    });
  }
}
