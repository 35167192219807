import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuard } from './auth/auth-guard.service'
import { AuthGuardRestrict } from './auth/auth-guard-restrict.service'
import { AuthService } from './auth/auth.service'
import { PublicRoutingModule } from './public-routing.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from 'src/app/shared/shared.module'

import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { HeaderComponent } from './product/header/header.component';
import { CardComponent } from './product/card/card.component';
import { PrintComponent } from './print/print.component';

import { HeadingComponent } from './print/heading/heading.component';
import { DownloadComponent } from './product/download/download.component';
import { PdfComponent } from './pdf/pdf.component';
import { SellsheetComponent } from './sellsheet/sellsheet.component';
import { SellsheetTemplateComponent } from './sellsheet/sellsheet-template/sellsheet-template.component';
import { SellsheetTemplatePBNAComponent } from './sellsheet/sellsheet-template-pbna/sellsheet-template-pbna.component';
import { SellsheetTemplateToCPBNAComponent } from './sellsheet/sellsheet-template-toc-pbna/sellsheet-template-toc-pbna.component';
import { AuthComponent } from './auth/auth/auth.component';

import { FormsModule } from '@angular/forms';
import { ProductModalActionsComponent } from './product-modal-actions/product-modal-actions.component';

import { FritolayProductViewerModule } from "@gc/fritolay-product-viewer";
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MainLoginComponent } from './main-login/main-login.component';

@NgModule({
  declarations: [
    HomeComponent,
    ProductComponent,
    HeaderComponent,
    CardComponent,
    PrintComponent,
    HeadingComponent,
    DownloadComponent,
    PdfComponent,
    SellsheetComponent,
    SellsheetTemplateComponent,
    SellsheetTemplatePBNAComponent,
    SellsheetTemplateToCPBNAComponent,
    AuthComponent,
    ProductModalActionsComponent,
    MainLoginComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    LoginModule,
    SharedModule,
    FormsModule,
    FritolayProductViewerModule,
    NgxImageZoomModule
  ],
  exports: [HomeComponent, ProductComponent, HeaderComponent, CardComponent, PrintComponent],
  providers: [AuthGuard, AuthService, AuthGuardRestrict, PublicRoutingModule]
})
export class PublicModule { }
