import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

import { CoreService } from 'src/app/core/services/core.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MenuCategory } from 'src/app/core/services/menu-categories.service';
import { AdminService } from '../../services/admin.service'
import { HomepageCategory } from '../../interfaces/homepage-category';

declare var $: any;

@Component({
  selector: 'fc-dashboard-categories',
  templateUrl: './dashboard-categories.component.html',
  styleUrls: ['./dashboard-categories.component.scss']
})
export class DashboardCategoriesComponent implements OnInit {
  @Output() showToastEvent = new EventEmitter<any>()

  categoryForm!: UntypedFormGroup
  categoryFormSubmitted: boolean = false
  categoriesL1?: MenuCategory[];
  categoriesL2?: MenuCategory[];
  currentCategory?: HomepageCategory;
  isATopLevelCategory: boolean = false
  parentIdL1: number | null = null
  parentIdL2: number | null = null

  constructor(
    private formBuilder: UntypedFormBuilder,
    private coreService: CoreService,
    private sharedService: SharedService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.getCategories()
    this.initCategoryForm()
  }

  private showModal(modalRef: string) {
    $("#black-overlay").addClass('visible')
    $(modalRef).modal('show');
  }

  private hideModal(modalRef: string) {
    $("#black-overlay").removeClass('visible')
    $(modalRef).modal('hide');
  }

  private selectValueValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (control.value === null || control.value === undefined || control.value === "null") {
      return { 'nullValue': true }
    }

    return null
  }

  private initCategoryForm() {
    this.categoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      categoryL1: [null, [Validators.required, this.selectValueValidator]],
      categoryL2: [null]
    })
  }

  addNewCategory() {
    this.initCategoryForm()
    this.categoryForm.reset()
    this.categoryFormSubmitted = false
    this.isATopLevelCategory = false

    if ($('#isATopLevelCategorySwitch').prop('checked') === true) {
      $('#isATopLevelCategorySwitch').trigger('click')
    }

    $('.category-level').prop('selectedIndex', 0)

    setTimeout(() => {
      this.showModal('#add-new-category-modal')
    });
  }

  onAddNewCategorySubmit() {
    this.categoryFormSubmitted = true;

    if (this.categoryForm.invalid) {
      return
    }

    this.confirmAddNewCategory()
  }

  closeAddNewCategoryModal() {
    this.hideModal('#add-new-category-modal')
  }

  private confirmAddNewCategory() {
    var newCategoryParentId = null

    if (this.isATopLevelCategory === false) {
      if (this.categoryForm.controls['categoryL1'].value !== "null" && this.categoryForm.controls['categoryL1'].value !== null) {
        newCategoryParentId = this.categoryForm.controls['categoryL1'].value

        if (this.categoryForm.controls['categoryL2'].value !== "null" && this.categoryForm.controls['categoryL2'].value !== null) {
          newCategoryParentId = this.categoryForm.controls['categoryL2'].value
        }
      } else {
        return
      }
    }

    this.adminService.createCategory(
      this.categoryForm.controls['categoryName'].value,
      newCategoryParentId)
      .subscribe(createCategoryData => {
        this.closeAddNewCategoryModal()
        this.showToast('New category created!')
        this.getCategories()
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  isATopLevelCategorySwitchChanged(event: any) {
    if (event.target.checked) {
      $('.category-level').prop('disabled', 'disabled')
      this.categoryForm.controls['categoryL1'].clearValidators()
      this.categoryForm.controls['categoryL1'].reset()
      this.categoryForm.controls['categoryL2'].reset()
      this.categoryForm.controls['categoryL1'].setValue(null)
      this.categoryForm.controls['categoryL2'].setValue(null)
      this.isATopLevelCategory = true
      this.parentIdL1 = null
      this.parentIdL2 = null
    } else {
      $('.category-level').prop('disabled', false)
      this.categoryForm.controls['categoryL1'].setValidators([Validators.required, this.selectValueValidator])
      this.isATopLevelCategory = false
      if(this.currentCategory) this.getParentCategory(this.currentCategory, false)
    }

    this.categoryForm.controls['categoryL1'].updateValueAndValidity()
  }

  categoriesL1Changed(event: any) {
    let selectIndex = event.target.selectedIndex

    this.categoryForm.controls['categoryL2'].reset()
    this.categoriesL2 = []

    if (selectIndex !== 0 && this.categoriesL1) {
      this.categoriesL2 = this.categoriesL1[selectIndex - 1].children;
    }
  }

  private getCategories(): void {
    this.coreService.getMenuCategories()
      .subscribe(menuCategoriesData => {
        this.categoriesL1 = menuCategoriesData;
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  showDeleteCategoryModal(category: HomepageCategory) {
    this.currentCategory = category

    setTimeout(() => {
      this.showModal('#delete-category-modal')
    });
  }

  closeDeleteCategoryModal() {
    this.hideModal('#delete-category-modal')
    this.currentCategory = undefined
  }

  deleteCategory(categoryId: any) {
    this.adminService.deleteCategory(parseInt(categoryId, 10))
      .subscribe(deleteCategoryData => {
        this.closeDeleteCategoryModal()
        this.showToast("Category with products deleted!")
        this.getCategories()
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }

  showUpdateCategoryModal(category: HomepageCategory) {
    this.categoriesL2 = []

    this.getParentCategory(category, true)
  }

  private getParentCategory(category: HomepageCategory | null, showModal: boolean) {
    if(category == null) return;

    this.sharedService.getParentCategory(parseFloat(String(category.id)))
      .subscribe(getParentCategoryData => {
        var parentIdL1: number | null = null
        var parentIdL2: number | null = null

        if (getParentCategoryData.parent !== null) {
          parentIdL1 = getParentCategoryData.parent?.id ?? null;

          if (getParentCategoryData.parent !== null && getParentCategoryData.parent?.parent !== null) {
            parentIdL1 = getParentCategoryData.parent?.parent?.id ?? null;
            parentIdL2 = getParentCategoryData.parent?.id ?? null;

            this.categoriesL2 = this.categoriesL1?.find(x => String(x.id) === String(parentIdL1))?.children ?? [];
          }
        }

        this.currentCategory = category
        this.categoryFormSubmitted = false
        this.categoryForm = this.formBuilder.group({
          categoryName: [this.currentCategory.name, Validators.required],
          categoryL1: [parentIdL1, [Validators.required, this.selectValueValidator]],
          categoryL2: [parentIdL2],
        })

        this.parentIdL1 = parentIdL1
        this.parentIdL2 = parentIdL2

        if (showModal) {
          setTimeout(() => {
            if (parentIdL1 === null) {
              $('#isATopLevelCategorySwitch2').trigger('click')
            }

            this.showModal('#update-category-modal')
          });
        }
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  closeUpdateCategoryModal() {
    this.hideModal('#edit-category-modal')
    this.currentCategory = undefined
    this.categoriesL2 = []
    this.parentIdL1 = null
    this.parentIdL2 = null
    this.isATopLevelCategory = false
  }

  onUpdateCategorySubmit() {
    this.categoryFormSubmitted = true;

    if (this.categoryForm.invalid) {
      return
    }

    this.updateCategory()
  }

  updateCategory() {
    var updateCategoryParentId = null

    if (this.isATopLevelCategory) {
      updateCategoryParentId = null
    } else {
      if (this.categoryForm.controls['categoryL1'].value !== "null" && this.categoryForm.controls['categoryL1'].value !== null) {
        updateCategoryParentId = this.categoryForm.controls['categoryL1'].value

        if (this.categoryForm.controls['categoryL2'].value !== "null" && this.categoryForm.controls['categoryL2'].value !== null) {
          updateCategoryParentId = this.categoryForm.controls['categoryL2'].value
        }
      } else {
        return
      }
    }

    this.adminService.updateCategory(
      this.currentCategory?.id,
      this.categoryForm.controls['categoryName'].value,
      updateCategoryParentId)
      .subscribe(updateCategoryData => {
        this.closeUpdateCategoryModal()
        this.showToast('Category updated!')
        this.getCategories()
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }
}
