<div class="container landscape">
  <div class="header">
    <div class="line line-1"></div>
    <div class="line line-2"></div>
    <div class="name">
      {{ product?.name }}
    </div>
    <!-- <img class="logo"
         src="assets/images/sellsheet/Fit_To_Win.png"> -->
  </div>
  <div class="content">
    <div class="column">
      <div class="section rationale">
        <div class="title">Rationale</div>
        <div class="section-content">
          <ul>
            <li *ngFor="let r of rationale">{{r}}</li>
          </ul>
        </div>
      </div>
      <div *ngIf="sellSheetEconomics?.turnsPerWeek || sellSheetEconomics?.estimatedTurnForBreakeven || sellSheetEconomics?.unitsToBreakeven || sellSheetEconomics?.estimatedAnnualProfitability"
           class="section roi">
        <div class="roi-title">PME Breakeven/ROI</div>
        <div class="roi-table">
          <div class="roi-cell roi-header ">Turns per Week</div>
          <div class="roi-cell roi-header">Estimated turn for Breakeven</div>
          <div class="roi-cell roi-header">Units to Breakeven</div>
          <div class="roi-cell roi-header">Estimated Annual Profitability</div>

          <div class="roi-cell roi-item">{{sellSheetEconomics?.turnsPerWeek}}</div>
          <div class="roi-cell roi-item">{{sellSheetEconomics?.estimatedTurnForBreakeven}}</div>
          <div class="roi-cell roi-item">{{sellSheetEconomics?.unitsToBreakeven}}</div>
          <div class="roi-cell roi-item">{{sellSheetEconomics?.estimatedAnnualProfitability}}</div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="section locations">
        <div class="title">Suggested Locations</div>
        <div class="section-content">
          <ul>
            <li *ngFor="let location of suggestedLocations">{{location}}</li>
          </ul>
        </div>
      </div>
      <div class="section keys-attr">
        <div class="title">Key Attributes</div>
        <div class="section-content">
          <div class="title-attr">Specifications:</div>
          <ul class="bullet">
            <li *ngFor="let specification of specifications">{{specification}}</li>
          </ul>
          <div class="title-attr">Packout:</div>
          <ul>
            <li *ngFor="let p of packout">{{p}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="column round">
      <div class="section product">
        <div class="details">
          <div class="product-image">
            <img [src]="imageName">
          </div>
          <span class="order">Order Details</span>
          <span>{{unitsPerCarton}} display / carton</span>
          <span class="space">{{product?.eqi}}</span>
          <span class="space">{{sellSheetEconomics?.price}}</span>
          <span>{{sellSheetEconomics?.fundingLevel}}</span>
        </div>
        <div class="dimensions">
          <span>Dimensions</span>
          15'W x 20"D x 70"H
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="line"></div>
    <div class="page">{{page}}</div>
  </div>
</div>