import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DownloadService } from 'src/app/core/services/download.service';
import { Product } from '../interfaces/product';
import { ProductProperties } from '../interfaces/product-properties';

declare var $: any;

@Component({
  selector: 'fc-product-modal-actions',
  templateUrl: './product-modal-actions.component.html',
  styleUrls: ['./product-modal-actions.component.scss']
})
export class ProductModalActionsComponent implements OnInit, AfterViewInit {

  @Input() product?: Product
  @Output() button360ClickedEmitter = new EventEmitter<void>()

  private prefixVideoUrl = "https://fritolay.blob.core.windows.net/images-products/videos"
  private nsmDomainUrl = "https://mcoe360.com/product-viewer"

  showButton360 = false
  showButtonVideo = false
  showButtonLink = false

  constructor(
    private downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    try {
      const productProperties: ProductProperties = JSON.parse(this.product?.properties ?? '{}');

      this.showButton360 = productProperties.has360View === true ? true : false
      this.showButtonVideo = productProperties.hasVideo === true ? true : false
      this.showButtonLink = productProperties.enableShareableLink === true ? true : false
    } catch (e) {
      console.log('Error parsing product properties: ' + e)
    }
  }

  ngAfterViewInit(): void {
    if (this.showButtonLink) {
      $('#button-link').popover({
        placement: 'top',
        content: 'Copied to clipboard!'
      })
    }
  }

  button360Clicked(): void {
    this.button360ClickedEmitter.emit()
  }

  buttonVideoClicked(): void {
    this.downloadService.downloadFile(`${this.prefixVideoUrl}/${this.product?.eqi}.mp4`)
  }

  buttonLinkClicked(): void {
    navigator.clipboard.writeText(`${this.nsmDomainUrl}/${this.product?.eqi}`);

    setTimeout(() => {
      $('#button-link').popover('hide')
    }, 2500);
  }

}
