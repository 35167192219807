import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../interfaces/product';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProductProperties } from '../../interfaces/product-properties';


type DisplaySpec = {
  packout?: string;
  rationale?: string;
  specifications?: string;
  suggestedLocations?: string;
  graphicExecution?: string;
}

type Economics = {
  price?: string;
  fundingLevel?: string;
  turnsPerWeek?: string;
  estimatedTurnForBreakeven?: string;
  unitsToBreakeven?: string;
  estimatedAnnualProfitability?: string;
}

@Component({
  selector: 'fc-sellsheet-template-pbna',
  templateUrl: './sellsheet-template-pbna.component.html',
  styleUrls: ['./sellsheet-template-pbna.component.scss']
})
export class SellsheetTemplatePBNAComponent implements OnInit {
  @Input() product?: Product;
  @Input() page: number = 0;

  sellSheetEconomics?: Economics;
  sellSheetDisplaySpec?: DisplaySpec;
  imageName = "";
  rationale: string[] = [];
  suggestedLocations: string[] = [];
  packout: string[] = [];
  specifications: string[] = [];
  unitsPerCarton: string = "";

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.product) {
      if (this.product.sellSheet) {
        this.sellSheetEconomics = JSON.parse(this.product.sellSheet.economics);
        this.sellSheetDisplaySpec = JSON.parse(this.product.sellSheet.displaySpec);
        this.rationale = this.sellSheetDisplaySpec?.rationale?.split('\n') ?? [];
        this.suggestedLocations = this.sellSheetDisplaySpec?.suggestedLocations?.split('\n') ?? [];
        this.packout = this.sellSheetDisplaySpec?.packout?.split('\n') ?? [];
        this.specifications = this.sellSheetDisplaySpec?.specifications?.split('\n') ?? [];
      }

      let imageCount: number = 1;
      try {
        const productProperties: ProductProperties = JSON.parse(this.product?.properties ?? '{}');
        imageCount = productProperties.imagesCount ?? 1;
        this.unitsPerCarton = productProperties.unitsPerCarton ?? '';
      } catch (e) {
        console.log('Error parsing product properties: ' + e)
      }

      if (imageCount > 1) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.eqi}-1x600.jpg`;
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product?.eqi}x600.jpg`;
      }
    }
  }
}
