import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { tap, delay } from 'rxjs/operators'
import { Router } from '@angular/router'

import { SharedService } from "src/app/shared/services/shared.service";
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthServiceAdmin {
    private mcoeAdminCookieName = "mcoeAdminSecureAccessExpirationTime"
    private mcoeAdminCookieMaxDays = 1

    isLoggedIn: boolean = window.localStorage.getItem("isLoggedIn") === "true" ? true : false

    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    login(username: string, password: string): Observable<boolean> {
        let isLoggedIn = (username === environment.adminUsername && password === environment.adminPassword)

        return of(true)
            .pipe(
                delay(1000),
                tap(val => {
                    this.isLoggedIn = isLoggedIn

                    if (this.sharedService.environmentIsMCOE()) {
                        if (this.isLoggedIn) {
                            this.createMcoeAdminCookie()
                        }
                    } else {
                        localStorage.setItem("isLoggedIn", isLoggedIn ? "true" : "false")
                    }
                })
            )
    }

    logout(): void {
        this.isLoggedIn = false
        localStorage.removeItem("isLoggedIn")

        if (this.sharedService.environmentIsMCOE()) {
            var date = new Date()
            date.setTime(date.getTime() + 1)

            var expires = "; expires=" + date.toUTCString()

            document.cookie = this.mcoeAdminCookieName + "=1" + expires + "; path=/"
        }

        this.router.navigate(['/login'])
    }

    userIsLoggedIn() {
        if (this.sharedService.environmentIsMCOE()) {
            return this.mcoeAdminCookieIsValid()
        } else {
            return this.isLoggedIn
        }

        return false;
    }

    private createMcoeAdminCookie() {
        var date = new Date()
        date.setTime(date.getTime() + (this.mcoeAdminCookieMaxDays * 24 * 60 * 60 * 1000))

        var expires = "; expires=" + date.toUTCString()

        document.cookie = this.mcoeAdminCookieName + "=1" + expires + "; path=/"
    }

    private mcoeAdminCookieIsValid() {
        if (document.cookie && document.cookie.indexOf(this.mcoeAdminCookieName + "=1") !== -1) {
            return true
        } else {
            return false
        }
    }

    isMcoeAdminAccessIsGranted() {
        return this.mcoeAdminCookieIsValid()
    }
}