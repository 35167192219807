<div id="wrapper" [ngClass]="{'fsv': sharedService.environmentIsFSV(), 'pbna': sharedService.environmentIsPBNA(), 'mcoe': sharedService.environmentIsMCOE()}">
  <div id="black-overlay" *ngIf="!isPrint && !isMobile"></div>
  <fc-navbar *ngIf="isMenuLoaded && !isPrint" [isFsvSecureAccess]="isFsvSecureAccess ?? false"></fc-navbar>
  <fc-menu-desktop *ngIf="!isMobile && !isPrint" (menuLoaded)="onMenuLoaded($event)"
    [isFsvSecureAccess]="isFsvSecureAccess ?? false" [desktopMenuSubjectEvent]="desktopMenuSubject.asObservable()">
  </fc-menu-desktop>
  <fc-menu-tree *ngIf="isMobile && !isPrint" [isMobile]="isMobile" [isLeftNav]="false"
    (menuLoaded)="onMenuLoaded($event)"></fc-menu-tree>
  <fc-breadcrumb *ngIf="isMobile && !isBugs"></fc-breadcrumb>
  <div appScrollSpy class="container main-container content py-0">
    <router-outlet *ngIf="isMenuLoaded" (activate)="onRouterOutletActivate($event)"></router-outlet>
    <fc-footer *ngIf="isMenuLoaded && !isPrint" [isFsvSecureAccess]="isFsvSecureAccess ?? false"></fc-footer>

    <div class="modal fade" id="inventory-modal" tabindex="-1" role="dialog" aria-labelledby="inventoryModal"
      aria-hidden="true" data-backdrop="false" *ngIf="!isPrint && isFsvSecureAccess">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="inventoryModal" *ngIf="sharedService.environmentIsMCOE()">Sell Sheets</h5>
            <h5 class="modal-title" id="inventoryModal" *ngIf="sharedService.environmentIsFSV()">Reports, Forms & Sell Sheets</h5>
          </div>
          <div class="modal-body">
            <div class="row flex-center-center">
              <div class="col-6 flex-center-center p-0" *ngIf="!sharedService.environmentIsMCOE()">
                <button class="btn w-100 p-0" (click)="epopInventoryReportClicked()">
                  <img class="w-100 ga-clic-inventory" src="assets/images/home/EPOP_Button.jpg"
                    data-titlega="Epop Inventory Report" *ngIf="sharedService.environmentIsMCOE()">
                    <img class="w-100 ga-clic-inventory" src="assets/images/home/reports_button.jpg"
                    data-titlega="Epop Inventory Report" *ngIf="sharedService.environmentIsFSV()">
                </button>
              </div>
              <!-- <div class="col-4 flex-center-center p-0">
                <button class="btn w-100 p-0" (click)="dcInventoryReportClicked()" disabled>
                  <img class="w-100 ga-clic-inventory" src="assets/images/home/DC_Button.jpg"
                    data-titlega="DC Inventory Report">
                </button>
              </div> -->
              <div class="col-6 flex-center-center p-0">
                <button class="btn w-100 p-0" (click)="sellSheetsClicked()">
                  <img class="w-100 ga-clic-inventory" src="assets/images/home/SELL_SHEET_button-16.png"
                    data-titlega="Sell Sheets">
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-1" (click)="closeInventoryModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<fc-custom-popup [popupKey]="'custom-popup-1'"></fc-custom-popup>
<fc-custom-popup [popupKey]="'custom-popup-2'"></fc-custom-popup>