import { Directive, ElementRef } from '@angular/core';
import { EventBrokerService } from '../shared/services/eventBroker.service';

declare var $: any;

@Directive({
    selector: '[appSizeSpy]'
})
export class SizeSpyDirective {
    private breakpoints: number[] = [728, 862];
    private currentBreakPoint = 0;

    constructor(
        private eventBroker: EventBrokerService,
        private el: ElementRef
    ) {
        $(window).on('resize', () => {
            this.spyElementWidth(el.nativeElement.offsetWidth);
        });
        this.spyElementWidth(el.nativeElement.offsetWidth);
    }

    private spyElementWidth(width: number) {
        let tmpBreakPoint = 0;
        this.breakpoints.forEach((bp: number) => {
            if (width >= bp) {
                tmpBreakPoint = bp;
            }
        });

        if (tmpBreakPoint !== this.currentBreakPoint) {
            this.currentBreakPoint = tmpBreakPoint;
            this.eventBroker.emit<void>('productsContainerSizeChanged', undefined);
        }
    }
}
