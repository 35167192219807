<div class="mt-3">
    <div>
        <button class="btn btn-3" (click)="addSection()">
            Add new section
        </button>
    </div>
</div>
<div class="mt-3" *ngIf="homepageSections">
    <table class="dashboard-headers-and-sections-table table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th scope="col" style="width: 35%;">Section name</th>
                <th scope="col" style="width: 15%;">Display Order</th>
                <th scope="col" style="width: 15%;">Show background</th>
                <th scope="col" style="width: 35%;" class="center">Action</th>
            </tr>
        </thead>
        <tbody *ngFor="let homepageSection of homepageSections">
            <tr>
                <th scope="row">
                    {{ homepageSection.name }}
                </th>
                <td>
                    #{{ homepageSection.displayOrder }}
                </td>
                <td>
                    <span *ngIf="homepageSection.showBackground === true">Yes</span>
                    <span *ngIf="homepageSection.showBackground !== true">No</span>
                </td>
                <td class="center">
                    <button class="btn btn-3 mr-2" (click)="showUpdateSectionModal(homepageSection)">
                        Edit
                    </button>
                    <button class="btn btn-3 mr-2" (click)="showDeleteSectionModal(homepageSection)">
                        Delete
                    </button>
                    <button class="btn btn-secondary" (click)="addSectionItem(homepageSection)">
                        Add new item
                    </button>
                </td>
            </tr>
            <tr>
                <td scope="row" colspan="4" class="center">
                    <button *ngIf="homepageSection.items && homepageSection.items.length > 0" [id]="'show-items-' + homepageSection.id"
                        type="button" class="btn btn-secondary btn-round btn-items showing"
                        (click)="showSectionItems(homepageSection.id)">
                        Show section items <span class="badge badge-light">
                            {{ homepageSection.items.length }}
                        </span>
                    </button>
                    <button *ngIf="homepageSection.items && homepageSection.items.length === 0" [id]="'show-items-' + homepageSection.id"
                        type="button" class="btn btn-secondary btn-round btn-items showing">
                        No section items
                    </button>
                    <button [id]="'hide-items-' + homepageSection.id" type="button"
                        class="btn btn-3 btn-round btn-items" (click)="hideSectionItems(homepageSection.id)">
                        Hide section items
                    </button>
                    <table [id]="'items-' + homepageSection.id" class="dashboard-headers-and-sections-table headers-and-sections-items table table-bordered
                        table-hover table-striped">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" style="width: 15%;" class="left">Image</th>
                                <th scope="col" style="width: 20%;" class="left">Name</th>
                                <th scope="col" style="width: 25%;" class="left">Target</th>
                                <th scope="col" style="width: 10%;" class="left">Display Order</th>
                                <th scope="col" style="width: 10%;" class="left">Badge</th>
                                <th scope="col" style="width: 20%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let homepageSectionItem of homepageSection.items">
                                <th scope="row" class="left">
                                    <div class="item-image">
                                        <img [src]="sharedService.getEnvironmentContainer() + '/assets/homepageCategories/' +
                                            homepageSectionItem.imageUrl + '?timestamp=' + timestamp">
                                    </div>
                                </th>
                                <td class="left">
                                    {{ homepageSectionItem.name }}
                                </td>
                                <td class="left">
                                    {{ homepageSectionItem.targetUrl }}
                                </td>
                                <td class="left">
                                    #{{ homepageSectionItem.displayOrder }}
                                </td>
                                <td class="left">
                                    <span
                                        *ngIf="homepageSectionItem.badgeKey !== null && homepageSectionItem.badgeKey !== 'null'">
                                        Yes
                                    </span>
                                    <span
                                        *ngIf="homepageSectionItem.badgeKey === null || homepageSectionItem.badgeKey === 'null'">
                                        No
                                    </span>
                                </td>
                                <td>
                                    <button class="btn btn-3 mr-2"
                                        (click)="showEditSectionItemModal(homepageSectionItem)">
                                        Edit
                                    </button>
                                    <button class="btn btn-3" (click)="showDeleteSectionItemModal(homepageSectionItem)">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="add-new-section-modal" tabindex="-1" role="dialog" aria-labelledby="addNewSectionModal"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div id="add-new-section-content-section" class="showing">
                <form [formGroup]="sectionForm" (submit)="onAddSectionSubmit()">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addNewSectionModal">
                            Add new section
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label>Section name *</label>
                                <input type="text" formControlName="sectionName" class="form-control"
                                    placeholder="Section name" [ngClass]="{ 'is-invalid': sectionFormSubmitted &&
                                sectionForm.controls['sectionName'].errors }" />
                                <div *ngIf="sectionFormSubmitted && sectionForm.controls['sectionName'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="sectionForm.controls['sectionName'].errors['required']">
                                        Section name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-4">
                                <label>Display order *</label>
                                <input type="text" formControlName="displayOrder" class="form-control" placeholder="0"
                                    [ngClass]="{ 'is-invalid': sectionFormSubmitted &&
                                sectionForm.controls['displayOrder'].errors }" />
                                <div *ngIf="sectionFormSubmitted && sectionForm.controls['displayOrder'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="sectionForm.controls['displayOrder'].errors['required']">
                                        Display order is required
                                    </div>
                                    <div *ngIf="sectionForm.controls['displayOrder'].errors['pattern']">
                                        Display order must contains digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 px-0">Show background *</div>
                            <div class="col-8 px-0">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="showBackground">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-new-section"
                            (click)="closeNewSectionModal()">
                            Close
                        </button>
                        <button class="btn btn-3 btn-new-section">
                            Add new section
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentSection" class="modal fade" id="delete-section-modal" tabindex="-1" role="dialog"
    aria-labelledby="deleteSectionModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteSectionModal">
                    Delete section
                </h5>
            </div>
            <div class="modal-body">
                Are you sure to delete {{ currentSection.name }} section?
                <br><br>
                All linked items will be also deleted.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-new-section" (click)="closeDeleteSectionModal()">
                    Close
                </button>
                <button class="btn btn-3 btn-new-section" (click)="deleteSection(currentSection.id)">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentSectionItem" class="modal fade" id="delete-section-item-modal" tabindex="-1" role="dialog"
    aria-labelledby="deleteSectionItemModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteSectionItemModal">
                    Delete section item
                </h5>
            </div>
            <div class="modal-body">
                Are you sure to delete this section item?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-new-section" (click)="closeDeleteSectionItemModal()">
                    Close
                </button>
                <button class="btn btn-3 btn-new-section" (click)="deleteSectionItem(currentSectionItem.id)">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentSection" class="modal fade" id="edit-section-modal" tabindex="-1" role="dialog"
    aria-labelledby="editSectionModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="sectionForm" (submit)="onEditSectionSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title" id="editSectionModal">
                        Edit section
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Section name *</label>
                            <input type="text" formControlName="sectionName" class="form-control"
                                placeholder="Section name" [value]="currentSection.name" [ngClass]="{ 'is-invalid': sectionFormSubmitted &&
                                sectionForm.controls['sectionName'].errors }" />
                            <div *ngIf="sectionFormSubmitted && sectionForm.controls['sectionName'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionForm.controls['sectionName'].errors['required']">
                                    Section name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label>Display order *</label>
                            <input type="text" formControlName="displayOrder" class="form-control" placeholder="0"
                                [value]="currentSection.displayOrder" [ngClass]="{ 'is-invalid': sectionFormSubmitted &&
                                sectionForm.controls['displayOrder'].errors }" />
                            <div *ngIf="sectionFormSubmitted && sectionForm.controls['displayOrder'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionForm.controls['displayOrder'].errors['required']">
                                    Display order is required
                                </div>
                                <div *ngIf="sectionForm.controls['displayOrder'].errors['pattern']">
                                    Display order must contains digits only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-4 px-0">Show background *</div>
                        <div class="col-8 px-0">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" formControlName="showBackground">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section" (click)="closeUpdateSectionModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="add-new-section-item-modal" tabindex="-1" role="dialog"
    aria-labelledby="addNewSectionItemModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="sectionItemForm" (submit)="onAddSectionItemSingleSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Add section item
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <label>Image * (.jpg, .png)</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="custom-file">
                                <input type="file" formControlName="itemFile" class="form-control custom-file-input"
                                    id="itemFile2" accept="image/jpeg,image/png" (change)="itemFileChange($event)"
                                    [ngClass]="{ 'is-invalid':
                                    sectionItemFormSubmitted &&
                                    sectionItemForm.controls['itemFile'].errors }">
                                <label class="custom-file-label" for="itemFile2">Choose file</label>
                                <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemFile'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="sectionItemForm.controls['itemFile'].errors['required']">
                                        Item image is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Name * (Use for mobile and Google Analytics)</label>
                            <input type="text" formControlName="itemName" class="form-control" placeholder="Name"
                                [ngClass]="{ 'is-invalid':
                                    sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemName'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemName'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemName'].errors['required']">
                                    Item name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Target *</label>
                            <input type="text" formControlName="itemTarget" class="form-control"
                                placeholder="/products/categories/1" [ngClass]="{ 'is-invalid':
                                    sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemTarget'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemTarget'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemTarget'].errors['required']">
                                    Item target is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label>Display order *</label>
                            <input type="text" formControlName="itemDisplayOrder" class="form-control" placeholder="0"
                                [ngClass]="{ 'is-invalid': sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemDisplayOrder'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemDisplayOrder'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemDisplayOrder'].errors['required']">
                                    Display order is required
                                </div>
                                <div *ngIf="sectionItemForm.controls['itemDisplayOrder'].errors['pattern']">
                                    Display order must contains digits only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Badge *</label>
                            <select class="form-control select-badge" formControlName="badge">
                                <option selected value="null">No badge</option>
                                <option *ngFor="let homepageBadge of homepageBadges" [value]="homepageBadge.key">
                                    {{ homepageBadge.displayName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section"
                        (click)="closeNewSectionItemModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Add new item
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="currentSectionItem" class="modal fade" id="edit-section-item-modal" tabindex="-1" role="dialog"
    aria-labelledby="editSectionItemModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="sectionItemForm" (submit)="onEditSectionItemSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Edit section item
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <div class="flex-center-center">
                                <div class="item-image large">
                                    <img [src]="sharedService.getEnvironmentContainer() + '/assets/homepageCategories/' +
                                    currentSectionItem.imageUrl + '?timestamp=' + timestamp">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <label>Image (.jpg, .png)</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="custom-file">
                                <input type="file" formControlName="itemFile" class="form-control custom-file-input"
                                    id="itemFile3" accept="image/jpeg,image/png" (change)="itemFileChange($event)">
                                <label class="custom-file-label" for="itemFile3">Choose file</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Name * (Use for mobile and Google Analytics)</label>
                            <input type="text" formControlName="itemName" class="form-control" placeholder="Name"
                                [value]="currentSectionItem.name" [ngClass]="{ 'is-invalid':
                                    sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemName'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemName'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemName'].errors['required']">
                                    Item name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Target *</label>
                            <input type="text" formControlName="itemTarget" class="form-control"
                                placeholder="/products/categories/1" [value]="currentSectionItem.targetUrl" [ngClass]="{
                                'is-invalid':
                                    sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemTarget'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemTarget'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemTarget'].errors['required']">
                                    Item target is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label>Display order *</label>
                            <input type="text" formControlName="itemDisplayOrder" class="form-control" placeholder="0"
                                [value]="currentSectionItem.displayOrder" [ngClass]="{ 'is-invalid': sectionItemFormSubmitted &&
                                sectionItemForm.controls['itemDisplayOrder'].errors }" />
                            <div *ngIf="sectionItemFormSubmitted && sectionItemForm.controls['itemDisplayOrder'].errors"
                                class="invalid-feedback">
                                <div *ngIf="sectionItemForm.controls['itemDisplayOrder'].errors['required']">
                                    Display order is required
                                </div>
                                <div *ngIf="sectionItemForm.controls['itemDisplayOrder'].errors['pattern']">
                                    Display order must contains digits only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Badge *</label>
                            <select class="form-control select-badge" formControlName="badge">
                                <option selected value="null">No badge</option>
                                <option *ngFor="let homepageBadge of homepageBadges" [value]="homepageBadge.key">
                                    {{ homepageBadge.displayName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section"
                        (click)="closeEditSectionItemModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>