import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchComponent } from './components/navbar/search/search.component';

import { MenuDesktopComponent } from './components/menu/desktop/menu.desktop.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { AdminModule } from '../admin/admin.module';
import { PublicModule } from '../public/public.module';
// import { NsmModule } from '../nsm/nsm.module';
import { SharedModule } from 'src/app/shared/shared.module'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'

import { GraphQLModule } from './graphql.module';

import { DevicesService } from 'src/app/core/services/devices.service';
import { DownloadService } from 'src/app/core/services/download.service';

import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [NavbarComponent, SearchComponent, MenuDesktopComponent, FooterComponent, PageNotFoundComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    AdminModule,
    // NsmModule,
    PublicModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GraphQLModule,

  ],
  exports: [NavbarComponent, MenuDesktopComponent, FooterComponent, SharedModule],
  providers: [DevicesService, DownloadService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }
}
