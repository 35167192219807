import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import bsCustomFileInput from 'bs-custom-file-input';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment'
import { AdminService } from '../../../services/admin.service'
import { HomepageSection } from 'src/app/admin/interfaces/homepage-section';
import { HomepageSectionItem } from 'src/app/admin/interfaces/homepage-section-item';
import { Setting } from '../../../interfaces/setting';

import { SharedService } from 'src/app/shared/services/shared.service';

declare var $: any;

@Component({
  selector: 'fc-dashboard-headers-and-sections',
  templateUrl: './dashboard-headers-and-sections.component.html',
  styleUrls: ['./dashboard-headers-and-sections.component.scss']
})
export class DashboardHeadersAndSectionsComponent implements OnInit {
  @Input() homepageSettings?: Setting[]
  @Output() showToastEvent = new EventEmitter<any>()

  sectionForm: UntypedFormGroup = new UntypedFormGroup({});
  sectionFormSubmitted: boolean = false
  sectionItemForm: UntypedFormGroup = new UntypedFormGroup({});
  sectionItemFormSubmitted: boolean = false
  itemFileData: File | null = null
  homepageSections?: HomepageSection[]
  currentSection: HomepageSection | null = null
  currentSectionItem: HomepageSectionItem | null = null
  timestamp?: number
  homepageBadges?: Setting[]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private adminService: AdminService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    bsCustomFileInput.init()

    this.initSectionForm()
    this.initSectionItemForm()
    this.getHomepageSections()
  }

  private initSectionForm() {
    this.sectionForm = this.formBuilder.group({
      sectionName: ['', Validators.required],
      displayOrder: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      showBackground: [false],
    })
  }

  private initSectionItemForm() {
    this.sectionItemForm = this.formBuilder.group({
      itemFile: [null, Validators.required],
      itemName: ['', Validators.required],
      itemDisplayOrder: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      itemTarget: ['', Validators.required],
      badge: [null]
    })
  }

  private showModal(modalRef: string) {
    $("#black-overlay").addClass('visible')
    $(modalRef).modal('show');
  }

  private hideModal(modalRef: string) {
    $("#black-overlay").removeClass('visible')
    $(modalRef).modal('hide');
  }

  addSection() {
    this.initSectionForm()
    this.sectionForm.reset()
    this.sectionFormSubmitted = false
    this.sectionItemForm.reset()
    this.sectionItemFormSubmitted = false
    this.itemFileData = null

    $('#add-new-section-content-section').addClass('showing')
    $('#add-new-section-content-first-item').removeClass('showing')

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()
      this.showModal('#add-new-section-modal')
    });
  }

  closeNewSectionModal() {
    this.hideModal('#add-new-section-modal')
  }

  onAddSectionSubmit() {
    this.sectionFormSubmitted = true;

    if (this.sectionForm.invalid) {
      return
    }

    //$('#add-new-section-content-section').toggleClass('showing')
    //$('#add-new-section-content-first-item').toggleClass('showing')

    this.generateNewSection()
  }

  backNewSectionModal() {
    /*$('#add-new-section-content-section').toggleClass('showing')
    $('#add-new-section-content-first-item').toggleClass('showing')*/
  }

  onAddSectionItemSubmit() {
    /*this.sectionItemFormSubmitted = true;

    if (this.sectionItemForm.invalid) {
      return
    }

    //this.uploadNewSectionImage()
    this.generateNewSection()*/
  }

  itemFileChange(fileInput: any) {
    this.itemFileData = <File>fileInput.target.files[0];
  }

  uploadNewSectionImage(_callback: any) {
    const formData = new FormData();
    formData.append('file', this.itemFileData!);

    this.httpClient.post(environment.endPoint + '/upload/asset?folder=homepageCategories', formData, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(events => {
        if (events.type === HttpEventType.Response) {
          if (events.status === 200) {
            _callback()
          } else {
            console.log('Error during file upload :', events.status, events.body)

            this.showToast("An error during the file upload has occured...")
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log('Error during file upload :', error)

        this.showToast("An error during the file upload has occured...")
      });
  }

  generateNewSection() {
    var showBackground = false

    if (this.sectionForm.controls['showBackground'].value === true) {
      showBackground = true
    }

    this.adminService.createSection(
      this.sectionForm.controls['sectionName'].value,
      parseInt(this.sectionForm.controls['displayOrder'].value),
      showBackground)
      .subscribe(createSectionData => {
        this.closeNewSectionModal()
        this.showToast('New section created!')
        this.getHomepageSections()
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  getHomepageSections() {
    this.timestamp = Date.now()
    this.homepageSections = []
    this.homepageBadges = []

    this.adminService.getHomepageSections()
      .subscribe(homepageSectionsData => {
        this.homepageSections = homepageSectionsData
        this.homepageBadges = this.homepageSettings?.filter(function (e) {
          return e.key.startsWith('badge-homepage')
        })
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  showSectionItems(sectionId: any) {
    $('#items-' + sectionId).addClass('showing')
    $('#show-items-' + sectionId).removeClass('showing')
    $('#hide-items-' + sectionId).addClass('showing')
  }

  hideSectionItems(sectionId: any) {
    $('#items-' + sectionId).removeClass('showing')
    $('#show-items-' + sectionId).addClass('showing')
    $('#hide-items-' + sectionId).removeClass('showing')
  }

  /* DELETE SECTION */

  showDeleteSectionModal(section: any) {
    this.currentSection = section

    setTimeout(() => {
      this.showModal('#delete-section-modal')
    });
  }

  closeDeleteSectionModal() {
    this.hideModal('#delete-section-modal')
    this.currentSection = null
  }

  deleteSection(sectionId: any) {
    this.adminService.deleteSection(sectionId)
      .subscribe(deleteSectionData => {
        if ((deleteSectionData.data as any)['deleteSection'] === true) {
          this.closeDeleteSectionModal()
          this.showToast("Section deleted!")
          this.getHomepageSections()
        } else {
          console.error('there was an error sending the mutation')
          this.showToast("An error during the mutation has occured...")
        }
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }

  /* UPDATE SECTION */

  showUpdateSectionModal(section: HomepageSection) {
    this.currentSection = section
    this.sectionFormSubmitted = false

    this.sectionForm = this.formBuilder.group({
      sectionName: [this.currentSection.name, Validators.required],
      displayOrder: [this.currentSection.displayOrder, [Validators.required, Validators.pattern("^[0-9]*$")]],
      showBackground: [this.currentSection.showBackground],
    })

    setTimeout(() => {
      this.showModal('#edit-section-modal')
    });
  }

  closeUpdateSectionModal() {
    this.hideModal('#edit-section-modal')
    this.currentSection = null
  }

  onEditSectionSubmit() {
    this.sectionFormSubmitted = true;

    if (this.sectionForm.invalid) {
      return
    }

    this.updateSection()
  }

  updateSection() {
    this.adminService.editSection(
      this.currentSection?.id!,
      this.sectionForm.controls['sectionName'].value,
      parseInt(this.sectionForm.controls['displayOrder'].value),
      this.sectionForm.controls['showBackground'].value
    )
      .subscribe(editSectionData => {
        this.closeUpdateSectionModal()
        this.showToast("Section updated!")
        this.getHomepageSections()
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }

  /* DELETE SECTION ITEM */

  showDeleteSectionItemModal(sectionItem: any) {
    this.currentSectionItem = sectionItem

    setTimeout(() => {
      this.showModal('#delete-section-item-modal')
    });
  }

  closeDeleteSectionItemModal() {
    this.hideModal('#delete-section-item-modal')
    this.currentSectionItem = null
  }

  deleteSectionItem(sectionItemId: any) {
    this.adminService.deleteSectionItem(sectionItemId)
      .subscribe(deleteSectionItemData => {
        if ((deleteSectionItemData.data as any)['deleteSectionItem'] === true) {
          this.closeDeleteSectionItemModal()
          this.showToast("Section item deleted!")
          this.getHomepageSections()
        } else {
          console.error('there was an error sending the mutation')
          this.showToast("An error during the mutation has occured...")
        }
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }

  /* ADD SECTION ITEM */

  addSectionItem(section: HomepageSection) {
    this.initSectionItemForm()
    this.sectionItemForm.reset()
    this.sectionItemFormSubmitted = false
    this.itemFileData = null
    this.currentSection = section

    $('.select-badge').prop('selectedIndex', 0)

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()
      this.showModal('#add-new-section-item-modal')
    });
  }

  closeNewSectionItemModal() {
    this.hideModal('#add-new-section-item-modal')
    this.currentSection = null
  }

  onAddSectionItemSingleSubmit() {
    this.sectionItemFormSubmitted = true;

    if (this.sectionItemForm.invalid) {
      return
    }

    this.uploadNewSectionImage(() => {
      this.createSectionItem()
    })
  }

  private createSectionItem() {
    this.adminService.createSectionItem(
      this.itemFileData?.name ?? '',
      this.sectionItemForm.controls['itemName'].value,
      this.sectionItemForm.controls['itemTarget'].value,
      parseInt(this.sectionItemForm.controls['itemDisplayOrder'].value),
      this.currentSection?.id!,
      this.sectionItemForm.controls['badge'].value)
      .subscribe(createSectionItemData => {
        this.closeNewSectionItemModal()
        this.showToast('New section item created!')
        this.getHomepageSections()
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  /* EDIT SECTION ITEM */

  showEditSectionItemModal(sectionItem: HomepageSectionItem) {
    this.currentSectionItem = sectionItem
    this.sectionItemFormSubmitted = false
    this.itemFileData = null
    this.sectionItemForm = this.formBuilder.group({
      itemFile: [null],
      itemName: [this.currentSectionItem.name, Validators.required],
      itemDisplayOrder: [this.currentSectionItem.displayOrder, [Validators.required, Validators.pattern("^[0-9]*$")]],
      itemTarget: [this.currentSectionItem.targetUrl, Validators.required],
      badge: [this.currentSectionItem.badgeKey]
    })

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()
      this.showModal('#edit-section-item-modal')
    });
  }

  closeEditSectionItemModal() {
    this.hideModal('#edit-section-item-modal')
    this.currentSectionItem = null
  }

  onEditSectionItemSubmit() {
    this.sectionItemFormSubmitted = true;

    if (this.sectionItemForm.invalid) {
      return
    }

    if (this.itemFileData !== null && this.itemFileData !== undefined) {
      this.uploadNewSectionImage(() => {
        this.updateSectionItem(this.itemFileData?.name ?? '')
      })
    } else {
      this.updateSectionItem(this.currentSectionItem?.imageUrl ?? '')
    }
  }

  updateSectionItem(imageName: string) {
    this.adminService.editSectionItem(
      this.currentSectionItem?.id!,
      imageName,
      this.sectionItemForm.controls['itemName'].value,
      this.sectionItemForm.controls['itemTarget'].value,
      parseInt(this.sectionItemForm.controls['itemDisplayOrder'].value),
      this.sectionItemForm.controls['badge'].value
    )
      .subscribe(editSectionItemData => {
        this.closeEditSectionItemModal()
        this.showToast("Section item updated!")
        this.getHomepageSections()
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }
}
